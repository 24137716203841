<template>
  <b-card>
    <div
      v-if="newStartup.image"
      class="text-center mb-2"
    >
      <b-avatar
        :src="newStartup.image"
        size="96"
      />
    </div>
    <b-list-group>
      <list-items
        :item-title="$t('Girişim İsmi')"
        :item-value="newStartup.title"
      />
      <list-items
        :item-title="$t('Şirket Adı ve Ünvanı')"
        :item-value="newStartup.company"
      />
      <list-items
        :item-title="$t('Faaliyet Alanı')"
        :item-data="newStartup.startup_activity_area"
      />
      <list-items
        :item-title="$t('Pazarlama Odağı')"
        :item-data="newStartup.startup_marketing_focus"
      />
      <list-items
        :item-title="$t('Girişimin Websitesi')"
        :item-value="newStartup.website"
      />
      <list-items
        :item-title="$t('E-Posta')"
        :item-value="newStartup.email"
      />
      <list-items
        :item-title="$t('E-Posta')"
        :item-value="newStartup.email"
      />
      <list-items
        :item-title="$t('Linkedin')"
        :item-value="newStartup.linkedin"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BAvatar } from 'bootstrap-vue'
import ListItems from '@/views/Admin/Startup_applies/PreApplicationView/components/ListItems.vue'

export default {
  name: 'Summary',
  components: {
    BCard,
    BListGroup,
    BAvatar,
    ListItems,
  },
  computed: {
    newStartup() {
      return this.$store.getters['preApplicationViewReport/getPreApplicationReport']
    },
  },
}
</script>

<style scoped>

</style>
